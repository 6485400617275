import React from 'react';
import { useDispatch } from 'react-redux';
import s from '../components/Auth/styles';
import { Header, Footer } from '../components/Auth/components';
import { ChangePasswd, CheckToken } from '../store/user/actions';
import { useParams } from 'react-router-dom';
import { collectionValidate } from '../utils/collectionValidate';
import { DefaultInput } from '../components/ui';

export const ChangePasswdPage: React.FC = () => {
  const [password, setNewPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [isOk, setIsOk] = React.useState(false);
  const [info, setInfo] = React.useState({ email: '', name: '' });
  const dispatch = useDispatch();

  const { token } = useParams();

  React.useEffect(() => {
    if (token) {
      dispatch(
      CheckToken.request({
          data: {
            token,
          },
          callBack: (succ, data) => {
            if (!succ && data.message) {
              setError(data.message);
              setIsOk(false);
            }
            if (succ) {
              setIsOk(true);
            }
          },
        })
      );
    }
  }, [token, dispatch]);

  const handleDone = () => {
    if (!password) return setError('Обов’язкове поле');
    if (!collectionValidate['password'].checkValue(password)) {
      return setError(collectionValidate['password'].error);
    }
    dispatch(
      ChangePasswd.request({
        data: {
          password,
          token,
        },
        callBack: (succ, data) => {
          if (!succ && data.message) {
            setError(data.message);
          }
          if (succ && data?.email && data?.name) {
            setInfo(data);
          } else {
            setError('Сталася помилка');
          }
        },
      })
    );
  };

  if (info.email && info.name) {
    return (
      <div style={{ height: '100%' }}>
        <Header />
        <s.WrapperS>
          <s.FeedbackS>
            <h2>{info.name}, пароль успішно змінено!</h2>
            <p style={{ fontWeight: ' bold', lineHeight: '20px' }}>Щоб увійти на сайт, використовуйте e-mail {info.email} і новий пароль</p>
            <p>
              Знайти відповіді на поширені запитання щодо роботи сайтiв i сервісів зможете у розділі <a href="/feedback">Допомога</a>.
            </p>
          </s.FeedbackS>
        </s.WrapperS>
        <Footer />
      </div>
    );
  }

  return (
    <div style={{ height: '100%' }}>
      <Header />
      <s.WrapperS>
        <s.FormS>
          <s.MainS>
            <s.TitleS>Відновлення доступу</s.TitleS>
            <s.WrapperCascadeS>
              <s.Cascade1S>
                <DefaultInput
                  type="password"
                  handleChange={(value) => {
                    if (isOk) {
                      if (!collectionValidate['password'].checkValue(value)) {
                        setError(collectionValidate['password'].error);
                      } else {
                        setError('');
                      }
                    }
                    setNewPassword(value);
                  }}
                  value={password}
                  error={error}
                />
              </s.Cascade1S>
              <s.Cascade2S />
              <s.Cascade3S>
                <b>
                  Придумайте
                  <br />
                  новий пароль
                </b>
              </s.Cascade3S>
            </s.WrapperCascadeS>
            <div style={{ overflow: 'hidden' }}>
              <s.Cascade1S>
                <s.BtnS disabled={!isOk} onClick={handleDone}>
                  Зберегти
                </s.BtnS>
              </s.Cascade1S>
              <s.Cascade2S />
            </div>
          </s.MainS>
          <s.FooterS>
            <s.WrapperLogoS />© Цифрове видавництво Expertus
          </s.FooterS>
        </s.FormS>
      </s.WrapperS>
      <Footer />
    </div>
  );
};

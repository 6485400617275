import React from 'react';
import s from './styles';
import { FieldNewPasswd } from './FieldNewPasswd';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { CheckUserProfile, UpdateUserProfile } from '../../../store/user/actions';
import moment from 'moment';
import { validatePhone } from '../../../utils/validatePhone';
import { collectionValidate } from '../../../utils/collectionValidate';
import { DefaultInput, SelectSearch } from '../../ui';
import { BasicDatePicker } from '../../ui/BasicDatePicker';
import { Companies } from './Companies';
import { TCompany } from './TCompany';

const uaCode = '+380 ';

const setPhoneFormat = (value: string) => {
  if (!value) return '';
  return value
    .split('')
    .map((it, i) => (i === 1 || i === 4 || i === 6 ? `${it} ` : it))
    .join('');
};

export const Profile: React.FC = () => {

  const newCompany = {
    edpou: '',
    company: ''
  } as TCompany

  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const links = queryParams.get('link');

  //console.log("link profile", links);

  const [email, setEmail] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [middleName, setMiddleName] = React.useState('');
  // const [birthdate, setBirthdate] = React.useState('');
  const [birthdate, setBirthdate] = React.useState<Date | null>(null);

  const [phone, setPhone] = React.useState(uaCode);
  const [position, setPosition] = React.useState('');
  const [isNewPassw, setIsNewPassw] = React.useState(false);
  const [error, setError] = React.useState<{ [x: string]: string }>({});
  const [isSucc, setIsSucc] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [messageEmail, setMessageEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [showCodeForm, setShowCodeForm] = React.useState(false); // показує форму для введення смс коду
  const [showCodeBtn, setShowCodeBtn] = React.useState(false); // показує кнопку підтведження телефону
  const [code, setCode] = React.useState(''); // код з смс
  const [disabled, setDisabled] = React.useState(false); // блокування кнопки збереження змін
  const [phoneChecked, setPhoneChecked] = React.useState(false); // статус телефона
  const [showText, setShowText] = React.useState(false); // інфо про час до наступного смс
  const [timer, setTimer] = React.useState(0); // час до наступного смс
  const [firtsTime, setFirtsTime] = React.useState(0); // дата відправки першої смс ( для перевірки 1 год )
  const [sendTime, setSendTime] = React.useState(0); // дата відправки актуальної смс ( для перевірки 10 хв )
  const [counter, setCounter] = React.useState(0); // кількість спроб відправки смс ( макс 3 на годину )
  const [counterSend, setCounterSend] = React.useState(0); // кількість спроб введення коду ( 10 )
  const [showResendBtn, setShowResendBtn] = React.useState(true); // показ кнопки повторної відправки смс
  const [showResendError, setShowResendError] = React.useState(false); // інфо заборони повторної відправки смс
  const [company, setCompany] = React.useState<TCompany>(newCompany);
  const [companies, setCompanies] = React.useState<TCompany[]>([]);

  const { User, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const HOUR_TIME = 60 * 60 * 1000; // 1 година для 3-х спроб
  const WAIT_TIME = 10 * 60 * 1000; // 10 хв для очікування вводу актуального смс
  const RESEND_TIME = 120; // 2 хв для очікування повторної відправки запиту на код
  const SET_LIMIT = 10; // кількість спроб введення коду ( 10 )

  React.useEffect(() => {    
    if (User.userProfile?.email) setEmail(User.userProfile.email);
    if (User.userProfile?.fname) setFirstName(User.userProfile.fname);
    if (User.userProfile?.lname) setLastName(User.userProfile.lname);
    if (User.userProfile?.mname) setMiddleName(User.userProfile.mname.replace('0', ''));
    if (User.userProfile?.phone) setPhone(`${uaCode}${setPhoneFormat(validatePhone(User.userProfile.phone))}`);
    // if (User.userProfile?.company) setCompany(User.userProfile.company);
    if (User.userProfile?.bday) setBirthdate(new Date(User.userProfile.bday));
    if (User.userProfile?.companies) setCompanies(User.userProfile.companies);

    if (User.userProfile?.confirm_data) {
      try {
        const confirmData = JSON.parse(User.userProfile.confirm_data);
        if (confirmData.firtsTime) {
          const now = new Date().getTime();
          const time = now - confirmData.firtsTime;
          if (time < HOUR_TIME) {
            setFirtsTime(confirmData.firtsTime);
            if (confirmData.counter) {
              setCounter(confirmData.counter);
            }
          }
        }
        if (confirmData.sendTime) {
          setSendTime(confirmData.sendTime);
        }

        if (confirmData.counterSend) {
          setCounterSend(confirmData.counterSend);
        }
      } catch (err) {
        // console.log(err);
      }
    }
  }, [User.userProfile, HOUR_TIME]);

  React.useEffect(() => { // таймер 2 хв для повторної відправки запиту на код
    if (showCodeForm && timer > 0) {
      let timeout = setTimeout(() => {
        setTimer(timer - 1);
        clearTimeout(timeout);
      }, 1000);
    } else {
      if (!showCodeForm) {
        setTimer(0);
      }
    }
  }, [timer, showCodeForm]);

  React.useEffect(() => { // таймер 2 хв для повторної відправки запиту на код
    if (firtsTime > 0) {
      const timeout = setInterval(() => {
        const now = new Date().getTime();
        const time = now - firtsTime;
        if (time > HOUR_TIME) {
          clearInterval(timeout);
          setFirtsTime(0);
          setShowResendError(false);
          setShowResendBtn(true);
          setCounter(0);
        }
      }, 1000);

      return () => clearInterval(timeout);
    }
  }, [firtsTime, HOUR_TIME]);

  React.useEffect(() => {    
    if (User.userProfile?.job_id && Configurations.data?.positions) {
      const job = Configurations.data.positions.find((it) => it.Id === User.userProfile?.job_id);
      if (job) {
        setPosition(job.Name);
      }
    }
    const verifyPhone = !!(User.userProfile?.verifyPhone && User.userProfile.verifyPhone === 1);
    setPhoneChecked(verifyPhone);
  }, [User.userProfile, Configurations.data]);


  const clearForms = () => { // очистка стейтів валідаціі телефона при якихось діях користувача
    setShowCodeForm(false);
    setShowCodeBtn(false);
    setMessageEmail('');
    setMessage('');
    setCode('');
    setDisabled(false);
    setShowText(false);
    setTimer(0);
  }

  const handleCheckPhone = () => { // формуємо смс код і шлемо клієнту
    const now = new Date().getTime();
    const time = now - firtsTime;
    if (counter < 3) {
      setTimer(0);
      setCounterSend(0);
      const curentPosition = Configurations.data?.positions.find((it) => it.Name === position);
      const data = {
        lastName,
        firstName,
        middleName,
        birthdate: birthdate ? moment(birthdate).format('DD.MM.YYYY') : '',
        phone: phone ? phone.replace(uaCode, '').replace(/[^0-9.]/g, '') : '',
        job_id: curentPosition ? curentPosition.Id : 0,
        firtsTime: firtsTime === 0 ? now : firtsTime,
        sendTime: now,
        counter: time < HOUR_TIME ? counter + 1 : 1,
        counterSend: counterSend
      };

      dispatch(
        CheckUserProfile.request({
          data,
          callBack: (success, data: { status: string }) => {
            if (success) {
              if (data.status === 'ok') {
                setShowCodeForm(true);
                setShowCodeBtn(false);
                setMessageEmail('');
                setMessage('')
                setTimer(RESEND_TIME);
                if (time < HOUR_TIME) {
                  setCounter(counter + 1)
                } else {
                  setCounter(1)
                }
                setSendTime(now)
                if (firtsTime === 0) {
                  setFirtsTime(now)
                }
              }
            } else {
              setIsError(true);
            }
          },
        })
      );
    } else {
      setShowResendBtn(false);
      setShowResendError(true);
    }
  }

  const handleSecondCheckCode = () => { // повторна відправка смс
    if (timer > 0) {
      setShowText(true);
    } else {
      handleCheckPhone();
    }
  }

  const handleCheckCode = () => { // фунція перевірки введеного коду з смс
    if (code) {
      const now = new Date().getTime();
      if (counterSend >= SET_LIMIT) {
        setMessageEmail('Вичерпано ліміт уведень. Спробуйте через годину');
        setShowCodeForm(false);
        setCode('');
      } else if (now - sendTime < WAIT_TIME) {
        const curentPosition = Configurations.data?.positions.find((it) => it.Name === position);
        const data = {
          lastName,
          firstName,
          middleName,
          birthdate: birthdate ? moment(birthdate).format('DD.MM.YYYY') : '',
          job_id: curentPosition ? curentPosition.Id : '',
          phone: phone ? phone.replace(uaCode, '').replace(/[^0-9.]/g, '') : '',
          code: code,
          counterSend: counterSend + 1
        };
        const errorValidate = validateUser(data)
        if(errorValidate){
          return 
        }
        dispatch(
          CheckUserProfile.request({
            data,
            callBack: (success, data: { status: string }) => {
              if (success) {
                if (data.status === 'ok') {
                  setPhoneChecked(true);
                  clearForms();
                  setCounterSend(0);
                } else {
                  setCounterSend(counterSend + 1);
                  if (data.status === 'old_code') {
                    setMessageEmail('Уведіть останній код, що Вам відправлено');
                  } else {
                    setMessageEmail('Неправильний код. Уведіть інший код');
                  }
                }
              } else {
                setIsError(true);
              }
            },
          })
        );
      } else {
        setMessageEmail('Код недійсний. Отримайте новий код');
      }
    } else {
      setMessageEmail('Уведіть код');
    }
  }
  const validateUser = (data:any) => {    
    const obj: { [x: string]: string } = {};
    for (const key in data) {
      if (key === 'lastName' || key === 'firstName' || key === 'phone') {
        const field = collectionValidate[key];
        if (!data[key]) {
          obj[key] = 'Обов’язкове поле';
        } else if (!field.checkValue(data[key])) {
          obj[key] = field.error;
        }
      }
      if (key === 'middleName' && data.middleName && !collectionValidate[key].checkValue(data[key])) {
        obj[key] = collectionValidate[key].error;
      }
      if (key === 'phone' && data.phone && data.phone.length < 9) {
        obj[key] = 'Номер телефону має неправильне значення';
      }
      if (key === 'email' && data[key] && data[key] !== User.userProfile?.email) {
        const field = collectionValidate[key];

        if (User.userProfile?.email && User.userProfile?.email.indexOf('@expertus.media') > -1) {
          obj[key] = 'Цей e-mail змінити неможливо';
        }
        if (!field.checkValue(data[key])) {
          obj[key] = 'email має неправильне значення';
        }
      }
      if (key === 'job_id' && !data.job_id && !data.position) {
        obj['position'] = 'Оберіть необхідну посаду';
      }
      if (key === 'position' && !collectionValidate[key].checkValue(data[key])) {
        obj['position'] = collectionValidate[key].error;
      }
      if (key === 'birthdate' && data.birthdate) {
        const err = validateDate(data.birthdate, true);
        if (err) {
          obj[key] = err;
        }
      }
    }
    const keys = Object.keys(obj);
    if (keys.length > 0) {
       setError(obj);
       return 'error'
    }
    return '';
  }
  const handleSave = () => { // функція збереження даних
    const curentPosition = Configurations.data?.positions.find((it) => it.Name === position);
    setMessageEmail('');
    setMessage('');
    setIsSucc(false);
    setIsError(false);
    const data = {
      lastName,
      firstName,
      middleName,
      email,
      birthdate: birthdate ? moment(birthdate).format('DD.MM.YYYY') : '',
      phone: phone ? phone.replace(uaCode, '').replace(/[^0-9.]/g, '') : '',
      job_id: curentPosition ? curentPosition.Id : '',
      position: '',
      company,
    };

    const errorValidate = validateUser(data)
    if(errorValidate){
      return 
    }

    if (!phoneChecked) {
      setShowCodeBtn(true);
      setMessageEmail('Щоб зберегти, підтвердьте телефон');
      setDisabled(true);
    } else {
      dispatch(
        UpdateUserProfile.request({
          data,
          callBack: (success, data: { code: number; value: string; message: string, messageEmail: string }) => {
            setDisabled(false);
            if (!success && data.value && data.message) {
              const obj: { [x: string]: string } = {};
              obj[data.value] = data.message;
              setError(obj);
            }
            if (success) {
              if (data.messageEmail) {
                setMessage(data.messageEmail);
              }
              setIsSucc(true);
              setCompany(newCompany);
              if(links) {
                  //console.log("redirect to", links);
                  window.location.href = links;
              }              
            } else {
              setIsError(true);
            }
          },
        })
      );
    }
  };

  const handleChangeCode = (value: string) => { // введення коду смс в інпут
    if (value.length > 0) {
      const str = value.replace(/[^0-9.]/g, '');
      if (!Number.isNaN(Number(str))) {
        setCode(str);
        setDisabled(!(str.length === 6));
      } else {
        setCode('');
      }
    }
  };

  const handleChangeError = (type: string) => {
    if (error[type]) {
      const obj = { ...error };
      delete obj[type];
      setError(obj);
    }
  };

  const handleChangePhone = (value: string) => { // введення телефону в інпут
    const str = value
      .replace(uaCode, '')
      .replace(/[^0-9.]/g, '')
      .replaceAll('.', '');
    const ph = setPhoneFormat(str);
    const test = `${uaCode}${ph}`.split(' ').join('');
    const isCorrect = test === User.userProfile?.phone;
    setPhoneChecked(isCorrect);
    if (isCorrect || showCodeForm) {
      clearForms()
    }

    if (value.length > 4) {
      if (value.length > phone.length) {
        if (!Number.isNaN(Number(str))) {
          setPhone(`${uaCode}${ph}`);
        }
      } else {
        setPhone(value);
      }
    }
  };

  const validateDate = (value: string, isCheck?: boolean) => {
    if (value.length === 10 || isCheck) {
      const day = parseInt(value.substring(0, 2));
      const month = parseInt(value.substring(3, 5)) - 1; // Місяці в JavaScript нумеруються з 0 до 11
      const year = parseInt(value.substring(6));
      const date = new Date();
      date.setFullYear(year);
      date.setMonth(month);
      date.setDate(day);
      if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
        return 'Дата має неправильне значення';
      }
      const dt = new Date();
      const old18 = dt.setFullYear(dt.getFullYear() - 18);
      if (date.getTime() > new Date(old18).getTime()) {
        return 'Вам має бути більше 18-ти років';
      }
      const difhundred = new Date().getFullYear() - date.getFullYear();
      if (difhundred > 100) {
        return `Вам, скоріше за все, менше 100 років 🙃
        Уведіть коректну дату, будь ласка.`;
      }
      if (date.getTime() > new Date().getTime()) {
        return 'Дата не може бути в майбутньому';
      }
    }
    return '';
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>)=> {
    if(e.key === '@' && email.includes('@')) {
      e.preventDefault();
    }
  }

  return (
    <>
      <s.AboutMePageS>
        <s.AboutMainS>
          <s.TitleS>Профіль</s.TitleS>
          <s.TabsS>
            <s.LinkWrapperS selected>
              <a href="/about/profile">Дані</a>
            </s.LinkWrapperS>
          </s.TabsS>
          <s.ProfileFormS style={{ marginBottom: '60px' }}>
            <s.LeftColumS>
              <DefaultInput
                label="Електронна пошта"
                value={email}
                handleChange={(value) => {
                  setIsSucc(false);
                  handleChangeError('email');
                  setEmail(value);
                }}
                handleKeyPress={handleKeyPress}
                isDone={error['email'] ? false : true}
                style={{ paddingRight: '45px', paddingLeft: '12px' }}
                error={error['email']}
              />
              {isNewPassw ? (
                <FieldNewPasswd handleClose={() => setIsNewPassw(false)} />
              ) : (
                <s.FormGroupS>
                  <s.BtnPasswdS onClick={() => setIsNewPassw(true)}>Змінити пароль</s.BtnPasswdS>
                </s.FormGroupS>
              )}
              <DefaultInput
                label="Прізвище"
                value={lastName}
                handleChange={(value) => {
                  handleChangeError('lastName');
                  setLastName(value);
                  setIsSucc(false);

                }}
                error={error['lastName']}
              />
              <DefaultInput
                label="Ім’я"
                value={firstName}
                handleChange={(value) => {
                  handleChangeError('firstName');
                  setFirstName(value);
                  setIsSucc(false);

                }}
                error={error['firstName']}
              />
              <DefaultInput
                label="По батькові"
                value={middleName}
                handleChange={(value) => {
                  handleChangeError('middleName');
                  setMiddleName(value);
                  setIsSucc(false);

                }}
                error={error['middleName']}
              />
              <BasicDatePicker
                label="Дата народження"
                error={error['birthdate']}
                value={birthdate}
                onChange={(value) => {
                  handleChangeError('birthdate');
                  setBirthdate(value);
                  setIsSucc(false);

                }}
              />
              <s.PhoneRowS>
                <DefaultInput
                  label="Номер телефону"
                  value={phone}
                  maxLength={17}
                  error={error['phone']}
                  isDone={phoneChecked}
                  handleChange={(value) => {
                    handleChangeError('phone');
                    handleChangePhone(value);
                    setIsSucc(false);

                  }}
                />
                {!phoneChecked && !showCodeForm && (
                  <s.BtnCheckS onClick={handleCheckPhone}>
                    <s.BtnContentS>Підтвердити</s.BtnContentS>
                  </s.BtnCheckS>
                )}
              </s.PhoneRowS>
              {showCodeForm && !showCodeBtn ? (
                <s.ConfirmColumS>
                  <s.ConfirmInfoS>
                    <p>На номер {phone} відправлено SMS з кодом підтвердження. Уведіть його.</p>
                    <p>Якщо номер неправильний, виправте його у полі Номер телефону</p>
                  </s.ConfirmInfoS>
                  {showText && timer > 0 && (
                    <s.InfoS>Замовити новий код ви зможете через {timer} секунд</s.InfoS>
                  )}
                  <DefaultInput
                    label=""
                    value={code}
                    maxLength={6}
                    error={error['code']}
                    handleChange={(value) => {
                      handleChangeError('code');
                      handleChangeCode(value);
                      setIsSucc(false);

                    }}
                  />
                  <s.BtnCheckS onClick={handleCheckCode}>
                    <s.BtnContentS>Підтвердити</s.BtnContentS>
                  </s.BtnCheckS>
                  {showResendBtn && (
                    <s.BtnSecondS onClick={handleSecondCheckCode}>
                      Не отримали код? Відправити код повторно
                    </s.BtnSecondS>
                  )}
                </s.ConfirmColumS>
              ) : null}
              {showResendError && (
                <s.BtnSecondS>
                  Ви вичерпали ліміт з трьох спроб. Отримати повторно код підтвердження Ви зможете через годину
                </s.BtnSecondS>
              )}

              <SelectSearch
                label="Посада"
                value={position}
                error={error['position']}
                handleChange={(value) => {
                  handleChangeError('position');
                  setPosition(value);
                  setIsSucc(false);

                }}
                options={Configurations.data ? Configurations.data.positions.map((it) => ({ value: it.Name, label: it.Name })) : []}
              />
              {/* <DefaultInput error={error['company']} label="Організація" value={company} handleChange={setCompany} /> */}
            </s.LeftColumS>
          </s.ProfileFormS>
         <Companies
            isSucc = { isSucc }
            companies = { companies }
            company = { company }
            setCompanies = { setCompanies }
            setCompany = { setCompany }
            setCompaniesError = { setDisabled }
            setIsSucc = { setIsSucc }
          />
          {!showCodeForm && (
            <s.ProfileNoticeS>
              Зберігаючи інформацію, Ви погоджуєтеся з{' '}
              <a href="/agreement" target="_blank">
                угодою про умови користування Сайтом
              </a>
            </s.ProfileNoticeS>
          )}
          {/* { `counter: ${counter}; counterSend: ${counterSend}; firtsTime: ${firtsTime}; sendTime: ${sendTime}; ` } */}
          <s.ProfileFormS>
            <>
              <s.LeftColumS>
                <s.BtnS disabled={disabled} onClick={handleSave}>
                  <s.BtnContentS>Зберегти зміни</s.BtnContentS>
                </s.BtnS>
              </s.LeftColumS>
              <div style={{ marginLeft: 'auto' }}>
                <div style={{ width: '170px' }}></div>
              </div>
            </>
            {messageEmail ? <div style={{ width: '100%', margin: '20px 0', color: 'red' }}>{messageEmail}</div> : null}
            {message ? <div style={{ width: '100%', margin: '20px 0', color: 'green' }}>{message}</div> : null}

            {isSucc && !messageEmail && !message ? <s.SuccS>Дані збережено</s.SuccS> : null}
            {isError ? <s.ErrorS>Сталася помилка</s.ErrorS> : null}
          </s.ProfileFormS>
        </s.AboutMainS>
      </s.AboutMePageS>

    </>
  );
};
